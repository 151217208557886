












































import { computed, defineComponent, ref } from "@vue/composition-api";

import NavigationComponent from "@/components/navigation/NavigationComponent.vue";

export default defineComponent({
  name: "MobileHeaderComponent",
  components: {
    NavigationComponent,
  },
  props: {
    type: {
      type: String,
      default: "Business Unicorns",
    },
    logo: {
      type: String,
      default: "LogoClaim",
    },
    page: {
      type: Object,
      default: {},
    },
    main: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props) {
    const isMenuOpen = ref(false);
    const isSearchOpen = ref(false);


    const isSearchPage = computed(
      () => props.page.backend_layout != "pagets_search"
    );

    const showSearch = computed(() => isSearchPage && props.type == "Business Unicorns");

    function openMenu() {
      isMenuOpen.value = !isMenuOpen.value;

      document.body.classList.add("overflow-hidden");
    }

    function toggleSearch() {
      const search = document.querySelectorAll(".search");

      if (!search) {
        return;
      }

      isSearchOpen.value = !isSearchOpen.value;
      search[1].classList.toggle("hidden");
    }

    function updateMenuState(value: boolean) {
      isMenuOpen.value = value;
    }

    return {
      openMenu,
      updateMenuState,
      toggleSearch,
      isMenuOpen,
      isSearchOpen,
      showSearch,
    };
  },
});
